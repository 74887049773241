export default {
  home: {
    Welcome: 'ยินดีต้อนรับ'
  },
  // 联盟邀请函
  kol: {
    language: 'ภาษา', //语言
    language_tip: 'าษาจะปรับโดยอัตโนมัติตามภาษาของระบบ นอกจากนี้ คุณยังสามารถเลือกภาษาที่เหมาะกับคุณได้ด้วยตนเอง', // 根据系统语言，我们将自动适配，你也可以手动选择适合自己的语言
    Login_invitation: 'การ์ดเชิญ KOL', // KOL 邀请函
    Login_enter: 'ยินดีต้อนรับ', // 欢迎
    Login_bottom_title1: 'วิธีการสั่งซื้อ', // 如何接单
    Login_bottom_title2: 'วิธีการหาเงิน', // 如何赚钱
    Login_not_register: 'ยังไม่มีบัญชี?', // 没有账号？
    Login_now_register: 'สมัครทันที', // 立即注册
    Login_select_country: 'เลือกประเทศหรือภูมิภาค', // 选择国家或地区
    Login_register: 'ลงทะเบียน', // 注册
    Login_goto_login: 'ลงชื่อเข้าใช้ด้วยวิธีอื่น' // 切换登录
  },
  // 品类汇率
  categoryExchangeRate: {
    Other: 'หมวดหมู่อื่นๆ', // 其它品类 หมวดหมู่อื่นๆ Other
    electronics: 'อุปกรณ์ดิจิทัล', // 数码 อุปกรณ์ดิจิทัล Electronics
    mobilePhone: 'โทรศัพท์', // 手机 โทรศัพท์ Mobile phone
    tablet: 'แท็บเล็ต', // 平板 แท็บเล็ต Tablet
    computer: 'คอมพิวเตอร์', // 电脑 คอมพิวเตอร์ Computer
    camera: 'กล้อง', // 相机  กล้อง Camera
    amusement: 'ความบันเทิง', // 娱乐 ความบันเทิง Amusement
    smartWearables: 'อุปกรณ์สวมใส่อัจฉริยะ', // 智能穿戴 อุปกรณ์สวมใส่อัจฉริยะ Smart Wearables
    audioVisualEquipment: 'อุปกรณ์ภาพและเสียง', // 影音设备 อุปกรณ์ภาพและเสียง Audio-visual equipment
    mothertBaby: 'แม่และเด็ก', // 母婴产品 แม่และเด็ก Mother & Baby
    pregnantWoman: 'แม่ลูกอ่อน', // 孕妇 แม่ลูกอ่อน Pregnant woman
    infant: 'เด็กทารก', // 婴幼儿 เด็กทารก Infant
    beauty: 'เสริมความงาม', // 美妝产品 เสริมความงาม Beauty
    makeupRemover: 'คลีนซิ่ง', // 卸妆 คลีนซิ่ง Makeup remover
    skinCare: 'บำรุงผิว', // 护肤 บำรุงผิว Skin care
    makeup: 'เครื่องสำอาง', // 化妆 เครื่องสำอาง Makeup
    householsSupplies: 'ของใช้ในบ้าน', // 家居用品 ของใช้ในบ้าน Househols Supplies
    householdappliances: 'เครื่องใช้ไฟฟ้าในบ้าน', // 家用电器 เครื่องใช้ไฟฟ้าในบ้าน Household appliances
    kitchenSupplies: 'ห้องครัว', // 厨房用品 ห้องครัว  Kitchen supplies
    bedding: 'ห้องนอน', // 床上用品 ห้องนอน Bedding
    homeDecoration: 'ของตกแต่งบ้าน', // 家庭装饰 ของตกแต่งบ้าน  Home decoration
    sanitaryLiving: 'สุขอนามัย', // 卫生起居 สุขอนามัย Sanitary living
    lighting: 'ดวงไฟและโคมไฟ', // 灯饰照明  ดวงไฟและโคมไฟ  Lighting
    houseMaintenance: 'เครื่องมือ', // 房屋维修  เครื่องมือ  House maintenance
    controlOfInsect: 'กำจัดศัตรูพืช', // 害虫防治  กำจัดศัตรูพืช  Control of insect
    flowerGardening: 'ทำสวนหย่อม', // 花卉园艺 ทำสวนหย่อม  Flower gardening
    decorationMaterial: 'วัสดุ' // 家装材料 วัสดุ  Decoration material
  },
  introduce: {
    panning: 'MXWINNER',
    user: 'เข้าถึงผู้ใช้',
    text: 'ให้ทุกคนหาเงินได้ง่ายๆ!',
    text2: 'แพลตฟอร์มดำเนินการแบรนด์ด้วยตนเองรูปแบบใหม่',
    platformGameplay: 'วิธีการใช้', // 平台玩法
    activityDescription: 'อธิบายเกี่ยวกับกิจกรรม', // 活动说明
    companyProfile: 'เกี่ยวกับ บริษัท', // 公司简介
    mission: 'วิสัยทัศน์และ พันธกิจ', // 使命愿景
    analysis: 'วิเคราะห์ ตลาด', // 市场分析
    businessModel: 'โมเดล ธุรกิจ', // 业务模式
    coreAdvantages: 'ใจความ สำคัญ', // 核心优势
    coreOverview: 'เกี่ยวกับใจความ สำคัญ', // 核心优势
    from: {
      headText: 'ลงทะเบียนและเข้าสู่ระบบ', // 登录即注册
      headText2: 'ตั้งรหัสผ่าน', // 设置密码
      openBeta: 'อธิบายเกี่ยวกับ การทดสอบสาธารณะ', // 公测说明
      nameRecommend: 'แนะนำ', // Namsai推荐
      phonePrompt: 'กรุณากรอกเบอร์โทรศัพท์', // 请输入手机号
      codePrompt: 'กรุณากรอกรหัส OTP', // 请输入验证码
      invitationCode: 'กรุณาใส่รหัสเชิญ (จำเป็น)', // 请输入邀请码（必填）
      btnTextCode: 'รับ OTP', // 获取验证码
      btnTextCode2: 'รับคืน', // 重新获取
      btnTextLogin: 'เข้าสู่ระบบ', // 登录
      agreementText: 'ดำเนินการต่อหากคุณยอมรับ', // 继续即表示您的已同意ดำเนินการต่อหากคุณยอมรับข้อตกลงการใช้บริการของ
      agreementText2: 'ข้อตกลงการใช้บริการของ MXCOME และนโยบายความเป็นส่วนตัว', // MXCOME服务协议与隐私政策
      passwodPrompt: 'กรุณาตั้งค่ารหัสผ่าน 8 หลักขึ้นไป', //请输入密码
      passwodPrompt2: 'กรุณาใส่รหัสผ่านอีกครั้ง', //请再输入一遍
      btnTextConfirm: 'ยืนยัน', // 确定
      Prompt:
        'รหัสผ่านต้องประกอบด้วยตัวอักษรและตัวเลข ตัวอักษรแรกต้องเป็นตัวพิมพ์ใหญ่ ความยาวอย่างน้อย 8 หลัก กรุณาเก็บรักษารหัสผ่านให้ปลอดภัย และหลีกเลี่ยงการตั้งรหัสผ่านที่คาดเดาได้ง่าย', // 密码提示
      text: 'ยินดีด้วย คุณได้รับบัตรกำนัล ',
      text2: '',
      text3: ' บัตรกำนัลใช้ซื้อของบนแพลตฟอร์ม',
      Login_mobile_error: 'เบอร์โทรศัพท์ไม่ถูกต้อง', //手机号格式错误
      Login_password_format_error: 'รหัสผ่านไม่ถูกต้อง กรุณากรอกตามเงื่อนไขด้านล่าง', //密码格式错误，请按照下面格式填写
      Login_password_or_confirm_password_error: 'รหัสผ่านไม่ถูกต้อง กรุณากรอกตามเงื่อนไขด้านล่าง', // 您输入的密码不一致，请重新输入
      congratulations: 'ยินดีด้วย คุณได้รับ ', // 恭喜，获得
      openBetaText: 'ยินดีด้วย คุณได้รับคำเชิญคุณสมบัติจากการทดสอบสาธารณะและบัตรกำนัล', // 公测邀请资格
      openBetaTextB: 'ล็อกอินAPP เพื่อเข้าร่วมฝ่าด่านพิชิตอั่งเปา ยิ่งเล่นยิ่งได้', // 登陆APP参与红包闯关，多推多得
      internalText: 'ยินดีด้วย คุณได้รับคำเชิญคุณสมบัติจากการทดสอบสาธารณะและบัตรกำนัล', // 内部邀请资格
      internalTextB: 'ล็อกอินAPP เพื่อรับภารกิจแพคเกจในหน้าแรก', // 登陆APP，首页参与指定套餐任务
      redEnvelope: 'ยินดีด้วย คุณได้รับคุณสมบัติฝ่าด่านพิชิตอั่งเปา มูลค่า', // 红包闯关资格
      redEnvelopeB: 'ล็อกอินAPP เพื่อรับภารกิจแพคเกจในหน้าแรก' // 登陆APP，首页参与指定套餐任务
    }
  },
  checkIn: {
    title: 'แพลตฟอร์มดำเนินการแบรนด์ด้วยตนเองรูปแบบใหม่', // 全新品牌自营平台
    text: 'ส่งคำขอเข้าร่วมแบรนด์ใน 3 นาที', // 3 分钟即可完成品牌入驻申请
    text2: 'ใช้เวลาตรวจสอบและส่งไอดีรหัสผ่านภายใน 48 ชั่วโมง', // 48 小时内完成审批及密码发放
    text3: 'ข้อตกลงการเข้าร่วมแบรนด์ของ MXCOME', // 《Mxcome商家服务协议》
    start: 'เข้าร่วมทันที' // 快速入驻
  },
  // 政策 policy
  policy: {
    monthlySalesAmount: 'ยอดขายในแต่ละเดือน (บาท)',
    marginLevel: 'อัตราค่ารับประกัน',
    // 保证金政策
    marginPolicy: {
      text: 'นโยบายค่ารับประกัน',
      textA:
        'เพื่อให้บริการที่มีคุณภาพสูงแก่ผู้ใช้จำนวนมาก สร้างความมั่นใจว่าแบรนด์จะยังคงให้บริการผลิตภัณฑ์และบริการที่มีคุณภาพสูงอย่างต่อเนื่อง ส่งเสริมการพัฒนาร่วมกัน แพลตฟอร์มจึงดำเนินการนโยบายเงินประกันแบบแบ่งระดับ โดยมีผลบังคับใช้ถึงวันที่ 31 ธันวาคม พ.ศ.2566 นโยบายมีเนื้อหาดังต่อไปนี้',
      textB: 'ร้านค้าแบรนด์ทำยอด 1,000 ออร์เดอร์แรก จะได้รับการยกเว้นค่ารับประกัน',
      textC:
        'ยอดหลังจากออร์เดอร์ที่ 1,000 เป็นต้นไป ระบบจะคำนวณมูลค่าการขายโดยอัตโนมัติ และดำเนินการตั้งค่าเงินประกันตามระดับ และระบบจะตัดยอดชำระค่ารับประกันโดยอัตโนมัติทุกวันที่ 28 ของทุกเดือน',
      textD: 'ยินดีคืนเงินภายใน 30 วัน หลังจากออกจากแพลตฟอร์ม'
    },
    // 技术服务费政策
    technicalServiceFeePolicy: {
      text: 'นโยบายค่าบริการด้านเทคนิค',
      textA:
        'MXCOME เป็นเศรษฐกิจแบบแบ่งปัน (Sharing Economy) มุ่งเน้นเป็นแพลตฟอร์มแบรนด์ที่ดำเนินการเอง มุ่งมั่นที่จะสร้างมูลค่าระยะยาวให้กับกลุ่มสังคมและแบรนด์ต่างๆ เนื่องจากบริษัทที่ดำเนินงานแบรนด์อาจมีหลายแบรนด์ในเครือ แพลตฟอร์มจึงดำเนินการนโยบายค่าบริการด้านเทคนิค ซึ่งจะมีผลบังคับใช้ถึงวันที่ 31 ธันวาคม พ.ศ.2566 นโยบายมีเนื้อหาดังต่อไปนี้',
      textB: 'หากมีร้านค้าเดียวจะได้รับการยกเว้นค่าบริการด้านเทคนิค',
      textC:
        'หากเพิ่มร้านค้าภายใต้บัญชีเดียวกันจะมีการเรียกเก็บค่าบริการด้านเทคนิค โดยค่าธรรมเนียมสำหรับร้านค้าแบรนด์ใหม่แต่ละร้านคือ 100,000 บาท/ปี'
    }
  },
  // brandCheckIn 页面
  brandCheckIn: {
    brandEntry: 'ข้อมูลแบรนด์', // 品牌录入
    contactInformation: 'ข้อมูลติดต่อ', // 联络信息
    contactName: 'ชื่อ-สกุลผู้ติดต่อ', // 联系人姓名
    inputPhone: 'กรอกเบอร์ติดต่อ', // 输入联系电话
    Email: 'อีเมล', // E-mail
    brandMessage: 'ข้อมูลแบรนด์', //品牌信息
    brand: 'ชื่อแบรนด์', //品牌名称
    brandCategories: 'ประเภทสินค้า', // 品牌类目
    relatedWebsites: 'เว็บไซต์', // 相关网站
    addLink: 'เพิ่มลิงก์', // 新增链接
    Data: 'เอกสารที่เกี่ยวข้อง', // 相关资料
    brandBrochure: 'โบว์ชัวร์แบรนด์', // 品牌宣传册
    nextStep: 'ต่อไป', // 下一步
    pleaseSelect: 'กรุณาเลือก', // 请选择
    Established: 'จดทะเบียนแล้ว', // 已设立
    notEstablished: 'ยังไม่จดทะเบียน', // 未设立
    localOperatingCompany: 'บริษัทดำเนินการในประเทศ', // 本地运营公司
    text: 'บริษัทที่จดทะเบียนแล้ว สามารถเปิดใช้งานร้านค้าได้ทันที', // 已设立运营公司可以直接激活店铺开展运营
    text2: 'บริษัทที่ยังไม่จดทะเบียน สามารถติดต่อแพลตฟอร์มให้ช่วยเหลือได้', // 未设立运营公司可以通过平台帮助完成设立
    brandInformation: 'ข้อมูลแบรนด์', //品牌资料
    brandSide: 'ฝ่ายแบรนด์', // 品牌方
    jointVenture: 'กิจการร่วมค้า', // 合资公司
    nationalAgency: 'ตัวแทนจำหน่ายต่างประเทศ', // 国家代理
    trademarkApplicationForm: 'หนังสือรับรองการจดทะเบียนเครื่องหมายการค้า', // 商标申请书
    trademarkCertificate: 'ใบทะเบียนเครื่องหมายการค้า', // 商标证书
    certificateOfIncorporation: '(แบรนด์)หนังสืออนุญาตให้ใช้เครื่องหมายการค้า', // (品牌)商标授权书
    text3: 'กรุณาอัปโหลดเอกสารตามความเป็นจริง คุณสามารถเพิ่มเติมเอกสารได้หลังจากเปิดใช้งานร้านค้า', // 请根据实际情况上传相关材料，您也可激活店铺后补充
    authenticationInformation: 'ส่งเอกสาร', // 补充认证
    companyInformationS: 'ข้อมูลบริษัท', // 公司资料
    directorSsignature: 'กรรมการลงลายมือชื่อ', // 董事签署
    authorizedSignature: 'ผู้รับมอบอำนาจลงลายมือชื่อ', // 授权签署
    registrationCertificate: 'ใบจดทะเบียนบริษัท', // 公司登记证书
    registrationCertificateS: 'เลขที่ใบจดทะเบียนบริษัท', // 公司登记证号
    affidavit: 'หนังสือรับรองบริษัท', // 公司宣誓书
    effectiveDate: 'ใช้ได้ถึงวันที่', // '有效截止日期',
    effectiveDateS: 'กรุณาเลือกวันที่', // '请选择截止日期'
    taxAdministration: 'ใบจดทะเบียนภาษีมูลค่าเพิ่ม', // '税务登记证
    taxAdministrationS: 'เลขที่ใบจดทะเบียนภาษีมูลค่าเพิ่ม', // '税务登记证号'
    text4: 'เอกสารของบริษัทจะต้องลงนามชื่อกรรมการผู้มีอำนาจหรือผู้รับมอบอำนาจพร้อมประทับตราบริษัท', // '公司认证资料需董事或授权代表签字盖章'
    directorEmpower: 'หนังสือมอบอำนาจ', // 董事授权书
    directorEmpowerTitle: 'ชื่อกรรมการ หรือผู้รับมอบอำนาจ', // 董事名字、授权代表名字
    continuouslyIncreasing: 'กำลังอัปเดตแบรนด์ที่เข้าร่วมใหม่...', // 持续增加中...
    text6: 'ขนาดรูปภาพไม่เกิน 3MB รองรับไฟล์ .jpg .png .jpeg .heic .pdf และ .bmp', // 单张图片大小不超过3MB，支持jpg、png、bmp格式
    text7: 'ขนาดไฟล์ไม่เกิน 10MB รองรับไฟล์ PDF', // 单个文件不能超过10MB,支持pdf
    text8: 'ชื่อแบรนด์ไม่สามารถซ้ำได้ กรุณากรอกใหม่อีกครั้ง', // 品牌名称不能重复，请重新输入
    Enter: 'กรุณากรอก', // '请输入'
    submitApplication: 'ส่งคำขอ', // '提交申请'
    text5: 'ส่งคำขอสำเร็จ', // '申请已成功提交'
    receiveEmail: 'อีเมลสำหรับรับข้อมูล：', // '接收邮件：'
    receiveRemind: 'ช่องทางรับแจ้งเตือน：', // '接收提醒：'
    complete: 'เสร็จสิ้น' // '完成'
  },
  // 合作成功页
  complete: {
    brandEntry: 'ข้อมูลแบรนด์', // 品牌录入
    confirm: 'ยืนยัน', // 确认
    submitForReview: 'ส่งคำขอ', // 提交审核
    successfullySubmitted: 'ส่งคำขอเข้าร่วมสำเร็จ', // 入驻申请已成功提交
    receiveAuditReminders: 'ช่องทางการรับแจ้งเตือน', // 接收审核提醒
    receiveKeyEmail: 'อีเมลสำหรับรับไอดีและรหัสผ่าน', // 接收密钥邮件
    textA: 'หากเลือกเสร็จสิ้น สามารถเข้าสู่ระบบเพื่อส่งเอกสารเพิ่มเติม', // 如选择完成，可用密钥登录店铺后补充认证
    textB: 'หากเอกสารครบถ้วนสมบูรณ์ คุณสามารถเลือกยื่นเอกสาร เพื่อลดระยะเวลาการตรวจสอบ', // 如资料完全，您可继续认证，节约审核时间
    complete: 'เสร็จสิ้น', // 完成
    continuingCertification: 'ยื่นเอกสาร', // 继续认证
    supplementaryCertification: 'ส่งเอกสาร', // 补充认证
    textC: 'กรุณาอัปโหลดเอกสารตามความเป็นจริง คุณสามารถเพิ่มเติมเอกสารได้หลังจากเปิดใช้งานร้านค้า', // 请根据实际情况上传相关资料，您也可激活店铺后补充认证
    textD: 'ตรวจสอบและส่งไอดีและรหัสผ่าน ภายใน 48 ชั่วโมง' // 48小时内完成审核及密钥发放
  },
  cooperate: {
    messageA: 'อยู่ระหว่างการพัฒนา โปรดรอติดตามความคืบหน้า', // 正在开发中,敬请期待期待!
    message: 'ใบรับรองเพิ่มเติมของคุณได้รับการส่งเรียบร้อยแล้ว!', // 补充提交提示语
    includingTax: ' รวมVAT', // 含税
    viewPolicy: 'อ่านข้อมูล', // 查看政策
    text: 'ฟรี 30 วัน', // 30天免费
    textA: '1,000 ออร์เดอร์แรก ฟรี', // 前 1,000 单免缴
    textB: 'ร้านค้าแบรนด์เดียว ฟรี', // 单一品牌店铺免费
    textC: '2566 ฟรี', // 2023免费
    shopWithdrawalFee: 'ค่าธรรมเนียมการถอนเงินร้านค้า', // 店铺提现手续费
    textD: 'เรียกเก็บโดยบุคคลที่สาม', // 第三方收取
    textE: 'การเรียกเก็บค่าใช้จ่ายข้างต้นนี้เป็นไปตามนโยบายผลประโยชน์ของแบรนด์ ปี 2566', // 以上收费项已享受MXCOME 2023年品牌优惠政策
    textF: 'นโยบายผลประโยชน์มีผลบังคับใช้ถึงวันที่ 31 ธันวาคม พ.ศ.2566 สิทธิการตีความอยู่กับบริษัทแพลตฟอร์ม MXCOME ทั้งหมด', // 优惠政策截止至2023年12月31日，解释权归属平台
    tabText: 'ตัวอย่างแบรนด์', // 真实案例
    tabText2: 'อัตราค่าบริการ', // 收费标准
    btnText: 'เข้าร่วมทันที', // 立即入驻
    CategoryRate: 'อัตราค่าบริการตามประเภทสินค้า', // 品类费率
    Margin: 'ค่าประกัน', // 保证金
    annualFee: 'ค่าบริการรายปี', // 技术服务年费
    OtherPlatforms: 'แพลตฟอร์มอื่นๆ', // 其他平台
    text2: '2566 ฟรี'
  },
  menus: {
    activity: 'บอร์ดกิจกรรม',
    news: 'ศูนย์รวมข่าวสาร',
    aboutUs: 'เกี่ยวกับเรา',
    partenship: 'ร่วมมือกันระหว่างแพลตฟอร์ม',
    joinUs: 'เข้าร่วมกับเรา'
  },
  nav: {
    play: 'ยิ่งเล่นยิ่งได้',
    login: 'ลงชื่อเข้าใช้',
    centerTitle: 'ยินดีต้อนรับเข้าสู่โลกของ', // 欢迎移民淘金世界
    centerTetx: 'ดำเนินการแบรนด์ด้วยตนเอง สร้างอนาคตร่วมกัน' // 品牌自营 共创未来
  },
  bottom: {
    innovation: 'นวัตกรรม',
    transform: 'เปลี่ยนแปลง',
    future: 'อนาคต',
    Copyright: 'MXCOME © ลิขสิทธิ์ 2023'
  },
  // home: {
  //   head: 'หาเงินแบบง่ายๆก็เป็นไลฟ์สไตล์อย่างหนึ่ง',
  //   p2: 'มีทั้งช้อปออนไลน์ ได้เข้าสังคม และสร้างคอนเทนต์อีกมากมายรอให้คุณได้ร่วมสนุก!',
  //   p3: 'ปลุกไอเดียใหม่ๆ และเปลี่ยนคอนเทนต์เป็นเงินได้ง่ายๆ',
  //   head2: 'ก้าวเข้าสู่ยุคของการจัดการแบรนด์ด้วยตนเอง',
  //   p4: 'ปรับโครงสร้างตลาดออนไลน์ และจัดการแบรนด์ด้วยตนเองเพื่อไปสู่ช่องทางใหม่!',
  //   p5: 'การแชร์คือคำตอบที่จะช่วยให้แบรนด์เติบโตอย่างต่อเนื่อง',
  //   endTime: 'เหลือเวลาอีก',
  //   deadline: 'วันและเวลาที่จะปิดรับ',
  //   scan: 'กรุณาแสกนเพื่อเข้าร่วมกิจกรรม',
  //   quickly: 'วิธีเป็นMXCOMER ใน 30 วินาที',
  //   checkpoint: 'ระดับ',
  //   countZero: 'นับถอยหลัง',
  //   day: 'วัน',
  //   hours: 'ชั่วโมง',
  //   minute: 'นาที',
  //   second: 'วินาที',
  //   top1000: 'MXCOMER 1000 รายชื่อแรกจะได้รับฟรีรางวัลอันดับดังนี้',
  //   search: 'ค้นหากิจกรรมและลุ้นรับรางวัล',
  //   Subscription: 'ร่อนทอง สมัครสมาชิกการตลาด',
  //   emailAddress: 'กรุณาใส่ที่อยู่อีเมล์',
  //   subscribe: 'สมัคร',
  //   message: 'เราจะส่งคูปองและแจ้งเตือนกิจกรรมเป็นครั้งคราว',
  //   downInfo: 'ดาวน์โหลดรหัส',
  //   NoActivity: 'ไม่มีการทำงาน!'
  // },
  news: {
    newsCenter: 'ศูนย์รวมข่าวสาร',
    MNews: 'MAXCOME NEWS',
    official: 'MXCOME เปิดตัวพร้อมตีตลาดอย่างเป็นทางการ',
    productNews: 'เนื้อหาเกี่ยวกับสินค้า',
    dynamic: 'แนวทางธุรกิจ',
    report: 'ข่าวสาร',
    statement: 'ประกาศ',
    search: 'ค้นหาประวัติข่าวสาร',
    readOn: 'อ่านข่าวต่อได้ที่นี่'
  },
  aboutUs: {
    brochure: 'ง่าย ๆ',
    Value: 'Value Co-Creation',
    Win: 'Win-Win',
    economics: 'การสร้างคุณค่าร่วมกัน ผลประโยชน์ร่วมกัน และ win-win', //价值经济 互利共赢
    highTech: 'เรามุ่งมั่นจะเป็นองค์กรเทคโนโลยีเพื่อสังคมที่ยอดเยี่ยมด้วยการพัฒนาอย่างยั่งยืน', //我们致力成为可持续发展的社会型伟大科技企业
    web: 'ระบบนิเวศธุรกิจทางเศรษฐกิจแบบแบ่งปันมุ่งเน้นไปที่การขับเคลื่อนโดย Web3.0 การแปลงข้อมูลไปสู่รูปแบบดิจิทัลและปัญญาประดิษฐ์เป็นหลัก', //专注于Web3、数字化、人工智能为核心技术驱动的分享经济商业生态
    profit: 'กำไรมีที่มาจากการบริการบนแพลตฟอร์มและสื่อโฆษณา', //主要利润来自平台服务及广告
    flagship: 'MXCOME-แพลตฟอร์มแนะนำโซเชียล Web3 แห่งแรกของโลก (บริการเนื้อหาเพื่อนสินค้าโภคภัณฑ์)', //商业旗舰-MXCOME 全球首家web3社交推荐平台
    mega: 'การผลิตเนื้อหาคุณภาพ ถ่ายทอดอย่างไร้ข้อจำกัด/เข้าสู่วิถีการอยู่ร่วมกันอย่างชาญฉลาด', //Mega content production, XEFR infinite possibility ; Come to smart Eco of TAO
    infinitePossibilities: '', //超级内容生产，传递无限可能
    zoology: 'ไปสู่วิถีการอยู่ร่วมกันอย่างชาญฉลาด', //进入道的智慧生态
    Spirit: 'The Spirit of MXCOME',
    soul: 'เอกลักษณ์ของ MXCOME', //淘金之魂
    message: 'วัฒนธรรมองค์กร', //创始寄语
    messageTip: 'นวัตกรรมเพื่อความยุติธรรม รักษ์ธรรมชาติ สนุกไปกับการใช้ชีวิต', //正义创新 敬畏自然 热爱生活
    values: 'คุณค่า', //价值观
    valuesTip: 'เคารพผู้ใช้และต้นแบบ', //尊重用户 尊重原创
    valuesTip1: 'แบรนด์ข้างต้น', //尊重用户 尊重原创
    vision: 'วิสัยทัศน์', //愿景
    visionTip: 'สร้างระเบียบทางธุรกิจรูปแบบใหม่', //构建全新商业秩序
    mission: 'หน้าที่', //使命
    missionTip: 'ให้ทุกคนได้เงินแบบสบายๆ', //让人人都轻松赚钱
    contactUs: 'ติดต่อเรา', //与我们取得联系
    contact: 'ติดต่อ', //联系
    ReceiveTime: 'เวลาทำการ 09.00 - 18.00 น. (หยุดวันเสาร์-อาทิตย์และวันหยุดนักขัตฤกษ์)', //接待时间：9:00-18:00(周六、周日及公众假期休息)
    Mailbox: 'อีเมล', //总务邮箱
    harken: 'เรายินดีรับฟัง', //我们用心倾听
    proposal:
      'ไม่ว่าจะเป็นข้อมูลเกี่ยวกับผลิตภัณฑ์ การออกแบบ หรือกฎระเบียบบนแพลตฟอร์ม หรือแม้แต่เสนอฟังก์ชันที่คุณต้องการ ก็สามารถแสดงความคิดเห็นของคุณได้ที่นี่', //无论是产品、设计、还是平台规则，或是你期待的功能，发表你的想法。
    award: 'ความคิดเห็นที่เป็นประโยชน์มีสิทธิ์รับรางวัล 500 - 5000 เหรียญMXCOME', //有效建议采纳将有机会获得500-5000淘金金币奖励
    configSuggest: 'แนะนำตอนนี้', //提交建议
    office: 'บริษัทของเรา', //我们的办公室
    address: 'ซอย รามอินทรา 32 แยก6แขวงท่าแร้ง เขตบางเขน กรุงเทพมหานคร 10220', //77/3 Soi Chuchitarom Alley Ratchadaphisek Subdistrict Din Dang District Bangkok 10400
    attention: 'ติดตามเรา', //关注我们
    moreContent: 'เนื้อหาที่น่าตื่นเต้นยิ่งขึ้น', //更多精彩内容
    userSuggest: 'ความคิดเห็นจากผู้ใช้', //用户建议
    name: 'ชื่อ-นามสกุล', //姓名
    region: 'ประเทศ', //区域
    thai: 'ไทย', //泰国
    china: 'จีน', //中国
    ID: 'ไอดี MXCOME', //MXCOME ID
    phone: 'เบอร์ติดต่อ', //联系电话
    suggestContent: 'กรุณากรอกข้อความความคิดเห็น', //请以文字描述建议内容
    upload: 'อัปโหลดไฟล์', //上传附件
    uploadTips: 'รองรับไฟล์ประเภท PDF WORD EXCEL ขนาดไม่เกิน 10 MB', //仅限10MB大小，文件格式支持（PDF WORD）
    submit: 'ส่ง', //提交
    GoHere: 'ทางนี้', // 去这里
    copySuccess: 'สําเนาสําเร็จ'
  },
  join: {
    joinM: 'เข้าร่วม MXCOME',
    welcome: 'ยินดีต้อนรับเข้าสู่ครอบครัว MXCOME',
    btnThai: 'ประเทศไทย',
    btnCh: 'จีน',
    changeWork: 'อยากเปลี่ยนงาน? MXCOMEขอเชิญคุณมาเป็นส่วนหนึ่งกับเรา',
    joinUs: 'เข้าร่วมกับเรา',
    whichWork: 'งานที่คุณมองหา',
    JobTitle: 'กรุณาใส่ตำแหน่งงาน',
    addressDetail: 'ที่อยู่', //详细地址
    workplacePlaceholder: 'โปรดเลือกสำนักงานในเมือง', //请选择城市办公室
    workplace: 'สถานที่ทำงาน', //工作地点
    thai: 'ประเทศไทย',
    china: 'จีน',
    usa: 'อเมริกา',
    BangkokStudio: 'สตูดิโอกรุงเทพ', //曼谷工作室
    ChangshaStudio: 'ฉางซา สตูดิโอ', //长沙工作室
    ShenzhenStudio: 'สตูดิโอเซินเจิ้น', //深圳工作室
    required: 'คุณสมบัติและความสามารถ',
    openPositions: 'ตำแหน่งที่เปิด', //开放岗位
    controlCenter: 'A.ศูนย์กลางการควบคุม', //管理中心
    ProductCenter: 'T.ศูนย์รวมสินค้า', //产品中心
    businessCenter: 'B.ศูนย์ธุรกิจ', //商业中心
    department: 'แผนก',
    design: '设计',
    research: '研发', //research and development
    assetManagement: '资管',
    hr: '人资',
    generalOffice: '总办', //General Office
    planning: '企划',
    operate: '运营',
    choose: 'กรุณาเลือก',
    type: 'ประเภทงาน',
    FullTime: 'งานประจำ',
    partTime: 'งานชั่วคราว',
    practice: 'ฝึกงาน',
    recruitmentPolicies: 'นโยบายการรับสมัคร',
    procedure: 'ขั้นตอนการรับเข้าทำงาน',
    redLine: 'จรรยาบรรณวิชาชีพ',
    markAsHoliday: 'ทำเครื่องหมายเป็นวันหยุด', //标记为放假日
    somePublicDaysHaveAdjust: 'วันหยุดนักขัตฤกษ์บางวันมีการปรับเปลี่ยน', //部分公众日存在调整
    holidayCalendar: 'ปฏิทินวันหยุด' //放假日历
  },
  collaboration: {
    invite: 'ต้อนรับเข้าสู่โลกของ MXCOME ',
    together: 'เคร่งครัด จริงใจ เพื่อมอบประโยชน์และสร้างอนาคตร่วมกัน',
    multi: 'ความร่วมมือแบบพหุมิติ',
    thai: 'ประเทศไทย',
    china: 'ประเทศจีน',
    interaction: 'ความร่วมมือกิจกรรม',
    platformOnboard: 'การเริ่มต้นใช้งานแพลตฟอร์ม', //平台入驻
    events: 'จัดกิจกรรมพิเศษของแบรนด์',
    information: 'ส่งข้อมูล',
    circular: 'ประกาศผลตรวจสอบอย่างเป็นทางการ',
    only: 'รองรับไฟล์ประเภท PDF WORD EXCEL ขนาดไม่เกิน 10 MB',
    search: 'ค้นหาชื่อแบรนด์',
    category: 'ประเภท',
    // 平台合作
    TypeCooperation: 'ประเภทการเข้าร่วม', //合作类型
    brand: 'แบรนด์', //品牌
    CheckInTime: 'วันที่เข้าร่วม', //入驻时间
    OfficialAuditCircular: 'ประกาศผลการตรวจสอบ', //官方审核通告
    BrandSettlement: 'แบรนด์เข้าร่วม', //品牌入驻
    BrandHome: 'ประเทศที่มาของแบรนด์', //品牌归属地
    BrandName: 'ชื่อแบรนด์', //品牌名称
    ContactName: 'ชื่อผู้ติดต่อ', //联系人名称
    AreaCode: 'รหัสประเทศ', //区号
    ContactNumber: 'เบอร์โทร', //联系电话
    emailBrand: 'อีเมล', //邮箱
    webBrand: 'เว็บไซต์แบรนด์', //品牌官网
    SubmitApplication: 'ส่งคำขอ', //提交申请
    YourApplicationPleaseWaitGoodNews: 'ส่งคำขอของท่านเรียบร้อยแล้ว กรุณารอทีมงานติดต่อกลับ', //您的申请已提交，请静候佳音
    PleaseKeepPhoneOpen: 'เจ้าหน้าที่อาจติดต่อกลับหากต้องการข้อมูลเพิ่มเติม กรุณารอรับสายเจ้าหน้าที่', //请保存电话畅通，如有必要我们将向您致电
    PleaseKeepPhoneOpen2: 'เจ้าหน้าที่จะตรวจสอบเสร็จสิ้นภายใน 48 ชั่วโมง ท่านจะได้รับข้อความ SMS และอีเมลยืนยันจากเรา', //我们将于48小时内完成审核，并通过短信及邮件方式发送
    know: 'รับทราบ', //知道了
    LOGO: 'โลโก้',
    deliveryTime: 'เวลาจัดส่ง',
    result: 'ผลการตรวจสอบ',
    remark: 'หมายเหตุ',
    pass: 'ผ่าน',
    reject: 'ถูกปฏิเสธ',
    SONY: 'SONY ประเทศไทย'
  }
}

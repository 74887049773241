export default {
  home: {
    Welcome: 'Welcome'
  },
  // 联盟邀请函
  kol: {
    language: 'Language', //语言
    language_tip:
      'We will automatically adapt the language according to your system language, or you can manually choose the language that suits you', // 根据系统语言，我们将自动适配，你也可以手动选择适合自己的语言
    Login_invitation: 'KOL Invitation', // KOL 邀请函
    Login_enter: 'Welcome', // 欢迎
    Login_bottom_title1: 'How to Take Orders?', // 如何接单
    Login_bottom_title2: 'How to Earn Money?', // 如何赚钱
    Login_not_register: "Don't have an account yet?", // 没有账号？
    Login_now_register: 'Sign Up Now', // 立即注册
    Login_select_country: 'Select a country or region', // 选择国家或地区
    Login_register: 'Register', // 注册
    Login_goto_login: 'Switch Login' // 切换登录
  },
  // 品类汇率
  categoryExchangeRate: {
    Other: 'Other', // 其它品类 หมวดหมู่อื่นๆ Other
    electronics: 'Electronics', // 数码 อุปกรณ์ดิจิทัล Electronics
    mobilePhone: 'Mobile phone', // 手机 โทรศัพท์ Mobile phone
    tablet: 'Tablet', // 平板 แท็บเล็ต Tablet
    computer: 'Computer', // 电脑 คอมพิวเตอร์ Computer
    camera: 'Camera', // 相机  กล้อง Camera
    amusement: 'Amusement', // 娱乐 ความบันเทิง Amusement
    smartWearables: 'Smart Wearables', // 智能穿戴 อุปกรณ์สวมใส่อัจฉริยะ Smart Wearables
    audioVisualEquipment: 'Audio-visual equipment', // 影音设备 อุปกรณ์ภาพและเสียง Audio-visual equipment
    mothertBaby: 'Mother & Baby', // 母婴产品 แม่และเด็ก Mother & Baby
    pregnantWoman: 'Pregnant woman', // 孕妇 แม่ลูกอ่อน Pregnant woman
    infant: 'Infant', // 婴幼儿 เด็กทารก Infant
    beauty: 'Beauty', // 美妝产品 เสริมความงาม Beauty
    makeupRemover: 'Makeup remover', // 卸妆 คลีนซิ่ง Makeup remover
    skinCare: 'Skin care', // 护肤 บำรุงผิว Skin care
    makeup: 'Makeup', // 化妆 เครื่องสำอาง Makeup
    householsSupplies: ' Househols Supplies', // 家居用品 ของใช้ในบ้าน Househols Supplies
    householdappliances: 'Household appliances', // 家用电器 เครื่องใช้ไฟฟ้าในบ้าน Household appliances
    kitchenSupplies: 'Kitchen supplies', // 厨房用品 ห้องครัว  Kitchen supplies
    bedding: 'Bedding', // 床上用品 ห้องนอน Bedding
    homeDecoration: 'Home decoration', // 家庭装饰 ของตกแต่งบ้าน  Home decoration
    sanitaryLiving: 'Sanitary living', // 卫生起居 สุขอนามัย Sanitary living
    lighting: 'Lighting', // 灯饰照明  ดวงไฟและโคมไฟ  Lighting
    houseMaintenance: 'House maintenance', // 房屋维修  เครื่องมือ  House maintenance
    controlOfInsect: 'Control of insect', // 害虫防治  กำจัดศัตรูพืช  Control of insect
    flowerGardening: 'Flower gardening', // 花卉园艺 ทำสวนหย่อม  Flower gardening
    decorationMaterial: 'Decoration material' // 家装材料 วัสดุ  Decoration material
  },
  introduce: {
    panning: 'MXWINNER',
    user: 'Connecting users',
    text: 'To enable everyone to make money with ease',
    text2: 'A new brand self-management platform',
    platformGameplay: 'Platform rules', // 平台玩法
    activityDescription: 'Activity description', // 活动说明
    companyProfile: 'Company profile', // 公司简介
    mission: 'Mission & Vision', // 使命愿景
    analysis: 'Market analyses', // 市场分析
    businessModel: 'Business model', // 业务模式
    coreAdvantages: 'Core strengths', // 核心优势
    coreOverview: 'Core overview', // 核心优势
    from: {
      headText: 'Login & Register', // 登录即注册
      headText2: 'Set Password', // 设置密码
      openBeta: 'Beta Instructions', // 公测说明
      nameRecommend: 'Recommended by ', // Namsai推荐
      phonePrompt: 'Please enter your mobile number.', // 请输入手机号
      codePrompt: 'Enter the OTP', // 请输入验证码
      btnTextCode: 'Get OTP', // 获取验证码
      invitationCode: 'Please input invitation code (required)', // 请输入邀请码（必填）
      btnTextCode2: 'Regain', // 重新获取
      btnTextLogin: 'Sign in', // 登录
      agreementText: 'By continuing, you agree to the', // 继续即表示您的已同意
      agreementText2: 'MXCOME Service Agreement and Privacy Policy.', // MXCOME服务协议与隐私政策
      passwodPrompt: 'Please enter the password', //请输入密码
      passwodPrompt2: 'Re-enter', //请再输入一遍
      btnTextConfirm: 'Confirm', // 确定
      Prompt:
        'Password should be composed of letters and numbers, with the first letter capitalized and with 8 digits at least Please take good care of your passwords and avoid using simple combinations', // 密码提示
      text: 'Congratulations, you have received a total of ',
      text2: 'vouchers!',
      text3: ' The voucher can be used to purchase any product on the platform',
      Login_mobile_error: 'Mobile number format error', //手机号格式错误
      Login_password_format_error: 'The password format is incorrect. Please fill in according to the format', //密码格式错误，请按照下面格式填写
      Login_password_or_confirm_password_error: 'The passwords you entered are inconsistent, so please re-enter', // 您输入的密码不一致，请重新输入
      congratulations: '恭喜，获得 ', //
      openBetaText: 'Congratulations, you ve won the public test invitation and', // 公测邀请资格 恭喜，获得公测邀请资格及
      openBetaTextA: 'voucher!', //
      openBetaTextB: 'Login to APP to participate in MXCOME tasks. The more products you recommend, the more earnings you will get.', // 登陆APP参与淘金任务
      internalText: 'Red Packet qualification!', // 内部邀请资格
      internalTextB: 'Login to the app and participate in the designated package tasks on the homepage.', // 登录APP，首页参与指定套餐任务
      redEnvelope: 'Congratulations on your', // 红包闯关资格
      redEnvelopeA: ' Red Packet qualification!', //
      redEnvelopeB: 'Login to the app and participate in the designated package tasks on the homepage.' // 登录APP，首页参与指定套餐任务
    }
  },
  // checkIn 页面
  checkIn: {
    title: 'New brand self-operating platform', // 全新品牌自营平台
    text: '3 minutes to complete your branding application', // 3 分钟即可完成品牌入驻申请
    text2: 'The review and key issuance will be completed within 48 hours', // 48 小时内完成审批及密码发放
    text3: 'MXCOME Merchant Service Agreement', // 《Mxcome商家服务协议》
    start: 'Quick Access' // 快速入驻
  },
  // 政策 policy
  policy: {
    monthlySalesAmount: 'Monthly Sales Amount (Baht)',
    marginLevel: 'Margin Ratio',
    marginPolicy: {
      text: 'Security Deposit Policy',
      textA:
        'To provide high-quality services to our users, ensure that brands continue to provide high-quality products and services, and promote common development, the platform will implement a tiered deposit policy. The policy will be valid untill December 31, 2023. The policy is as follows:',
      textB: 'Brand stores do not need to pay a deposit for the first 1,000 transactions.',
      textC:
        'After 1,000 transactions are completed, the system will automatically calculate the sales amount and execute the tiered deposit setting. The sales amount will be confirmed and automatically adjusted on the 28th of each month.',
      textD: 'The deposit will be returned within 30 days after withdrawing from the platform.'
    }, // 保证金政策
    technicalServiceFeePolicy: {
      text: 'Technical Service Fee Policy',
      textA:
        'MXCOME is a sharing economy platform that positions itself as a self-operated brand platform. It is committed to creating long-term value for the general public and brands. Given that a brand operation company may have multiple brands, the platform implements a technical service fee policy. Valid untill December 31, 2023, the policy is as follows:',
      textB: 'Single brands do not need to pay a technical service fee.',
      textC:
        'When a new brand store is added to the same account, the platform will charge a technical service fee. The fee is 100,000 baht per year for each store.'
    } // 技术服务费政策
  },
  // brandCheckIn 页面
  brandCheckIn: {
    brandEntry: 'Brand entry', // 品牌入驻
    contactInformation: 'Contact information', // 联络信息
    contactName: 'Contact name', // 联系人姓名
    inputPhone: 'Enter contact phone number', // 输入联系电话
    Email: 'E-mail', // E-mail
    brandMessage: 'Brand information', //品牌信息
    brand: 'Brand name', //品牌名称
    brandCategories: 'Brand category', // 品牌类目
    relatedWebsites: 'Related networks', // 相关网站
    addLink: 'Additional links', // 新增链接
    Data: 'Related information', // 相关资料
    brandBrochure: 'Brand brochure', // 品牌宣传册
    nextStep: 'Next', // 下一步
    pleaseSelect: 'Please select', // 请选择
    Established: 'Established', // 已设立
    notEstablished: 'Not established', // 未设立
    localOperatingCompany: 'Local operating company', // 本地运营公司
    text: 'If operations have established, the brand can directly activate the shop to carry out operations', // 已设立运营公司可以直接激活店铺开展运营
    text2: 'If operations have not established, the brand can use the platform to you set up the operation', // 未设立运营公司可以通过平台帮助完成设立
    brandInformation: 'Brand information', //品牌资料
    brandSide: 'Brand', // 品牌方
    jointVenture: 'Joint ventures', // 合资公司
    nationalAgency: 'Country agent', // 国家代理
    trademarkApplicationForm: 'Certification of Trademark Registration', // 商标申请书
    trademarkCertificate: 'Trademark certificate', // 商标证书
    certificateOfIncorporation: '(Brand) Trademark Certificate of Authorisation', // (品牌)商标授权书
    text3: 'Please upload the relevant materials according to the actual situation, you can also add these after activating the shop', // 请根据实际情况上传相关材料，您也可激活店铺后补充
    authenticationInformation: 'Authentication information', // 认证资料
    companyInformationS: 'Company information', // 公司资料
    directorSsignature: 'Directors signature', // 董事签署
    authorizedSignature: 'Authorized signature', // 授权签署
    registrationCertificate: 'Corporate registration certificate', // 公司登记证书
    registrationCertificateS: 'Company registration certificate number', // 公司登记证号
    affidavit: 'Company affidavit', // 公司宣誓书
    effectiveDate: 'Effective deadline', // '有效截止日期',
    effectiveDateS: 'Please choose the deadline', // '请选择截止日期'
    taxAdministration: 'Tax registration Certificate', // '税务登记证
    taxAdministrationS: 'Tax registration certificate number', // '税务登记证号'
    text4: "The company's certification documents need to be signed and stamped by a director or authorized representative", // '公司认证资料需董事或授权代表签字盖章'
    directorEmpower: 'Director s Authorization', // 董事授权书
    directorEmpowerTitle: 'Name of Director, Name of Authorized Representative', // 董事名字、授权代表名字
    continuouslyIncreasing: 'Continuously increasing...', // 持续增加中...
    text6: 'Single image size not more than 3MB, support jpg, png, pdf, bmp, jpeg, heic format', // 单张图片大小不超过3MB，支持jpg、png、bmp格式
    text7: 'Single file size not more than 10MB, support pdf format', // 单个文件不能超过10MB,支持pdf
    text8: 'The brand name cannot be repeated, please re-enter', // 品牌名称不能重复，请重新输入
    Enter: 'Please enter', // '请输入'
    submitApplication: 'Submit application', // '提交申请'
    text5: 'Application has been successfully submitted', // '申请已成功提交'
    receiveEmail: 'Receive emails：', // '接收邮件：'
    receiveRemind: 'Receive reminder ：', // '接收提醒：'
    complete: 'Done' // '完成'
  },
  // 合作成功页
  complete: {
    brandEntry: 'Brand Entry', // 品牌录入
    confirm: 'Confirm', // 确认
    submitForReview: 'Send', // 提交审核
    successfullySubmitted: 'Your application to join has been successfully submitted', // 入驻申请已成功提交
    receiveAuditReminders: 'Receive review result', // 接收审核提醒
    receiveKeyEmail: 'Receive key issuance email', // 接收密钥邮件
    textA: 'If you choose to complete, you can use the key to log in to the store and supplement the authentication', // 如选择完成，可用密钥登录店铺后补充认证
    textB: 'If the information is complete, you can continue the certification and save the review time.', // 如资料完全，您可继续认证，节约审核时间
    complete: 'Complete', // 完成
    continuingCertification: 'Continue the certification', // 继续认证
    supplementaryCertification: 'Supplementary Certification', // 补充认证
    textC:
      'Please upload the relevant materials according to the actual situation. You can also supplement the certification after activating the store', // 请根据实际情况上传相关资料，您也可激活店铺后补充认证
    textD: 'Complete audit and key distribution within 48 hours' // 48小时内完成审核及密钥发放
  },
  cooperate: {
    messageA: 'Under development, please wait', // 正在开发中,敬请期待!
    message: 'Your supplementary certification materials have been successfully submitted!', // 补充提交提示语
    includingTax: 'VAT included', // 含税
    viewPolicy: 'view', // 查看政策
    text: 'Free 30 days', // 30天免费
    textA: 'first 1,000 orders free of charge', // 前 1,000 单免缴
    textB: 'Free for single-brand stores', // 单一品牌店铺免费
    textC: '2023 Free', // 2023免费
    shopWithdrawalFee: 'Store withdrawal Service Fee', // 店铺提现手续费
    textD: 'Third-Party Collection', // 第三方收取
    textE: 'The above-mentioned charges have enjoyed the MXCOME 2023 Brand Preferential Policy', // 以上收费项已享受MXCOME 2023年品牌优惠政策
    textF: 'The Preferential Policy will be valid until December 31, 2023, and the interpretation of the policy belongs to the platform', // 优惠政策截止至2023年12月31日，解释权归属平台
    tabText: 'Real case', // 真实案例
    tabText2: 'Fees and charges', // 收费标准
    btnText: 'Join now', // 立即入驻
    CategoryRate: 'Category rate', // 品类费率
    Margin: 'Deposit', // 保证金
    annualFee: 'Annual fee for technical services', // 技术服务年费
    OtherPlatforms: 'Other platforms', // 其他平台
    text2: '2023 Free'
  },
  menus: {
    activity: 'Exciting Activity', //精彩活动
    news: 'News Center', //新闻中心
    aboutUs: 'About Us', //关于我们
    partenship: 'Platform Cooperation', //平台合作
    joinUs: 'Join Us' //加入我们
  },
  nav: {
    play: 'MORE PLAY,\nMORE EARN', // 越玩越赚
    login: 'Operation login', // 运营登录
    centerTitle: 'MXCOME, Welcome to the MXCOME world', // 欢迎移民淘金世界
    centerTetx: 'Brand Self-Operated Co-creating The Future' // 品牌自营 共创未来
  },
  bottom: {
    innovation: 'Innovation', //创新
    transform: 'changes', //改变
    future: 'the future', //未来
    Copyright: 'MXCOME © Copyright 2023'
  },
  // home: {
  //   head: 'Making money easily is a way of life.', //轻松赚钱是一种生活方式
  //   p2: 'New online shopping, social networking and content scenes are waiting for you to join!', //全新的网购、社交、内容创作场景等你来玩
  //   p3: 'Stimulating creativity and making money are simple and efficient.', //我们专注提升用户价值，让内容变现简单高效
  //   head2: 'Enter a new era of brand self-management"', //进入“你即品牌”时代
  //   p4: 'Reconstruct the e-commerce scene. Brand self-management is a channel change!', //持续赋能品牌价值！
  //   p5: 'Sharing is justice, continuously empowering brand growth."', //构建「真·品牌自营」的全新商业生态
  //   endTime: 'remaining time', //剩余时间
  //   deadline: 'cut-off time', //截止时间
  //   scan: 'Please scan the code to join activities', //请扫码参与活动
  //   quickly: 'Become MXCOMERS in 30 sec.', //30S淘金达人速成
  //   checkpoint: 'Level', //关卡
  //   countZero: 'Count down', //倒计时
  //   day: 'Day', //天
  //   hours: 'Hour', //时
  //   minute: 'minute', //分
  //   second: 'second', //秒
  //   top1000: 'The top 1,000 MXCOMERS will receive the following ranking awards for free', //前1000名淘金达人将免费获得以下排名大奖
  //   search: 'search activities and prizes', //查询活动及中奖
  //   Subscription: 'Gold Rush Marketing Subscription',
  //   emailAddress: 'Please fill in E-mail address', //请输入邮箱地址
  //   subscribe: 'subscribe', //订阅
  //   message: 'We will issue vouchers and brand activities notices from time to time', //我们将不定期发放优惠券及品牌活动通知
  //   downInfo: 'Scan Code',
  //   NoActivity: 'No activity at the moment!'
  // },
  news: {
    newsCenter: 'News Center', //新闻中心
    MNews: 'MXCOME News',
    official: 'Directly hitting the market dynamics, MXCOME official authority releases', //直击市场动态，MXCOME官方权威发布
    productNews: 'Product News', //产品消息
    dynamic: 'Operational trends', //运营动态
    report: 'Media report', //媒体报道
    statement: 'official statement', //官方声明
    search: 'Search news history', //搜索历史新闻动态
    readOn: 'Please continue to read' //请继续阅读
  },
  aboutUs: {
    brochure: 'BROCHURE', //手册
    Value: 'Value Co-Creation', //价值经济
    Win: 'Win-Win', //共赢
    economics: 'Value Co-Creation, Mutual Benefit and Win-win', //'价值经济 互利共赢',
    highTech: 'We are committed to becoming a great social and technology company with sustainable development.', //'我们致力成为可持续发展的社会型伟大科技企业',
    web: 'Focus on sharing economy driven by Web3, digitalization and artificial intelligence as core technologies', //'专注于Web3、数字化、人工智能为核心技术驱动的分享经济商业生态',
    profit: 'Profits mainly come from platform services and advertising', //'主要利润来自平台服务及广告',
    flagship: `MXCOME-The world's first Web3 social recommendation platform (commodity friend content service)`, //MXCOME-全球首家Web3社交推荐平台  (商品   好友   内容   服务)
    mega: 'Mega content production, XEFR infinite possibility ; Come to the smart Eco of TAO', //'超级内容生产，传递无限可能，进入道的智慧生态'
    infinitePossibilities: '', //'超级内容生产，传递无限可能',
    zoology: 'Come to the smart Eco of TAO', //'进入道的智慧生态',
    Spirit: 'The Spirit of MXCOME', //'MXCOME的精神'
    soul: 'Soul of gold rush', //'淘金之魂',
    message: "Founder's Message", //'创始寄语',
    messageTip: 'Adhere to justice and innovation, revere nature, and enjoy life', //'正义创新 敬畏自然 热爱生活',
    values: 'Values', //'价值观',
    valuesTip: 'Respect users and originality', //'尊重用户 尊重原创',
    valuesTip1: 'Above brands', //'以上品牌',
    vision: 'Prospect', //'愿景',
    visionTip: 'Build a new business order', //'构建全新商业秩序',
    mission: 'Mission', //'使命',
    missionTip: 'Let everyone make money easily', //'让人人都轻松赚钱',
    contactUs: 'Contact with us', //'与我们取得联系',
    contact: 'contact', //'联系',
    ReceiveTime: 'Reception time: Monday to Friday 9:00-18:00 (closed on Saturdays, Sundays and public holidays)', //'接待时间：9:00-18:00(周六、周日及公众假期休息)',
    Mailbox: 'Mailbox', //'总务邮箱',
    harken: 'Listen with our heart', //'我们用心倾听',
    proposal: 'We listen attentively to the ideas of product, design, platform rules, or features you expect.', //'无论是产品、设计、还是平台规则，或是你期待的功能，发表你的想法。',
    award: 'If the effective suggestions are adopted, you will have a chance to get 500 to 5000 gold coins for gold rush.', //'有效建议采纳将有机会获得500-5000淘金金币奖励',
    configSuggest: 'Suggest now', //'提交建议',
    office: 'Office Address', //'我们的办公室',
    address: 'Soi Ramintra 32 Intersection 6Tha Raeng Subdistrict, Bang Khen District, Bangkok 10220',
    attention: 'Follow us', //'关注我们',
    moreContent: 'More content', //'更多精彩内容',
    userSuggest: 'Your suggestions', //'用户建议',
    name: 'Name', //'姓名',
    region: 'Region', //'区域',
    thai: 'Thailand', //'泰国',
    china: 'China', //'中国',
    ID: 'MXCOME ID',
    phone: 'Contact number', //'联系电话',
    suggestContent: 'Please write down your suggestion in words.', //'请以文字描述建议内容',
    upload: 'Upload attachments', //'上传附件',
    uploadTips: '10MB size only, (PDF WORD EXCEL) File formats are allowed', //'仅限10MB大小，文件格式支持（PDF WORD）',
    submit: 'Submit', //'提交'
    GoHere: 'go here',
    copySuccess: 'copy succeeded'
  },
  join: {
    joinM: 'Join MXCOME',
    welcome: 'Welcome to join the MXCOME family',
    btnThai: 'Thailand',
    btnCh: 'china',
    changeWork: 'Want to change a job? MXCOME sincerely invites you to join us.', //想换工作？MXCOME诚邀您加入。
    joinUs: 'Join us', //加入我们
    whichWork: 'What new job do you want?', //想换什么工作？
    JobTitle: 'Please enter the position name', //请输入职位名称
    addressDetail: 'Address', //详细地址
    workplacePlaceholder: 'Please select a city office', //请选择城市办公室
    workplace: 'work place', //工作地点
    thai: 'Thailand', //泰国
    china: 'China', //中国
    usa: 'United States', //美国
    BangkokStudio: 'Bangkok studio', //曼谷工作室
    ChangshaStudio: 'Changsha studio', //长沙工作室
    ShenzhenStudio: 'Shenzhen studio', //深圳工作室
    required: 'Skills and Qualifications Requirements', //技能与资格要求
    openPositions: 'open positions',
    controlCenter: 'A.control center',
    ProductCenter: 'T.product center',
    businessCenter: 'B.business center',
    department: 'department',
    design: 'design',
    research: 'research and development',
    assetManagement: 'asset management',
    hr: 'hr',
    generalOffice: 'general office', //General Office
    planning: 'planning',
    operate: 'operate',
    choose: 'Please choose', //请选择
    type: 'Job type', //职位类型
    FullTime: 'Full-time', //全职
    partTime: 'Part-time', //兼职
    practice: 'Internship', //实习
    recruitmentPolicies: 'Recruitment policy', //招聘政策
    procedure: 'Induction process', //入职流程
    redLine: 'Prohibition of occupation', //职业红线
    markAsHoliday: 'mark as holiday', //标记为放假日
    somePublicDaysHaveAdjust: 'Some public days have adjustments', //部分公众日存在调整
    holidayCalendar: 'Holiday calendar'
  },
  collaboration: {
    invite: 'Sincerely welcome to and join in our MXCOME World', //诚邀移民MXCOME的世界
    together: 'Strictly abide by the contract, cooperate sincerely to benefit the world, and create a better future together',
    multi: 'Multidimensional cooperation', //多维合作
    thai: 'Thailand', //泰国
    china: 'China', //中国
    interaction: 'activity cooperation', //活动合作
    platformOnboard: 'platform onboarding', //平台入驻
    events: 'Host a brand event', //承办品牌专场
    information: 'Deliver cooperative information', //投递合作资料
    circular: 'Official review notice', //官方审核通告
    only: '10MB size only, (PDF WORD EXCEL) File formats are allowed', //仅限10MB大小，文件格式支持（PDF WORD EXCEL)
    search: 'Search brand name', //搜索品牌名称
    category: 'Category', //类别
    brand: 'Brand', //品牌
    LOGO: 'LOGO',
    deliveryTime: 'Delivery time', //投递时间
    result: 'Application result', //申请结果
    remark: 'Remark', //备注
    pass: 'Pass', //通过
    reject: 'Reject',
    SONY: 'SONY Thailand', //SONY泰国
    // 平台合作
    TypeCooperation: 'Cooperation type', //合作类型
    brand: 'Brand', //品牌
    CheckInTime: 'Time of entry', //入驻时间
    OfficialAuditCircular: 'Official review time', //官方审核通告
    BrandSettlement: 'Brand Entry', //品牌入驻
    BrandHome: 'Country of Brand', //品牌归属地
    BrandName: 'Brand name', //品牌名称
    ContactName: 'Contact name', //联系人名称
    AreaCode: 'Area code', //区号
    ContactNumber: 'Phone number', //联系电话
    emailBrand: 'E-mail', //邮箱
    webBrand: 'Brand website', //品牌官网
    SubmitApplication: 'Submit application', //提交申请
    YourApplicationPleaseWaitGoodNews: 'Your application has been submitted, please wait for notifications', //您的申请已提交，请静候佳音
    PleaseKeepPhoneOpen: 'Please keep your phone on, we will call you if necessary.', //请保存电话畅通，如有必要我们将向您致电
    PleaseKeepPhoneOpen2: 'We will complete the review within 48 hours, and send you messages via SMS and email.', //我们将于48小时内完成审核，并通过短信及邮件方式发送
    know: 'Got it' //知道了
  },
  userProtocol: `<!DOCTYPE html>
  <html>
  <head>
      <meta charset="utf-8">
      <title>MXCOME User Registration Protocol</title>
  </head>
  <body>
  <h3>
      User Registration Protocol
  </h3>
  
  <p>
      This agreement is divided into three parts: Privacy Policy, MXCOME Account Use Agreement and Exemption Agreement. If you agree to register, you agree to all the contents of this “User Registration Agreement”.
  </p>
  
  <p>
      Privacy Policy (updated on March 1, 2023)
  </p>
  
  <p>
      Thank you for trusting and using MXCOME's products and services. We have updated the Privacy Policy according to the latest laws, regulations and regulatory policies of various countries and regions (hereinafter referred to as "laws and regulations"). Please read and fully understand the following terms.
  </p>
  
  <h3>
      I. Introduction
  </h3>
  
  <p>
      MXCOME strictly abides by laws and regulations and follows the following privacy protection principles to provide you with safer and more reliable services:
  </p>
  
  <h3>
      1.1 Safety and reliability
  </h3>
  
  <p>
      We do our best to prevent your information from being leaked, damaged or lost through reasonable and effective information security technology and management processes.
  </p>
  
  <h3>
      1.2 Choice making
  </h3>
  
  <p>
      We provide you with convenient information management options so that you can make the right choice and manage your personal information.
  </p>
  
  <h3>
      1.3 Communication Secrets Protection
  </h3>
  
  <p>
      We strictly abide by laws and regulations, protect your communication secrets and provide you with safe communication services.
  </p>
  
  <h3>
      1.4 Reasonable necessity
  </h3>
  
  <p>
      In order to provide better service to you and other users, we only collect necessary information.
  </p>
  
  <h3>
      1.5 Clear and transparent
  </h3>
  
  <p>
      We try our best to introduce the privacy policy to you in simple and easy-to-understand terms, so that you can clearly understand how we handle information.
  </p>
  
  <h3>
      1.6 Integrating Privacy Protection into Product Design
  </h3>
  In all aspects of product or service development, we integrate many factors such as law, product and design into the concept of privacy protection.
  <p>
      I hope you can read the Privacy Policy (hereinafter referred to as "this Policy") carefully and learn more about how we collect and use information, so that you can better understand our services and make appropriate choices. If you use the MXCOME service, you agree with what we have stated in this policy. Please contact us if you have any questions.
  </p>
  
  <h3>
      II. Information and Collection
  </h3>
  
  <p>
      We only collect the information necessary to realize the function of the product according to the principles of legality, justness and necessity. You provide information voluntarily when using our service.
  </p>
  
  <h3>
      2.1 The information you filled in when registering your account.
  </h3>
  
  <p>
      For example, it includes the nickname and mobile phone number you filled in when you registered your MXCOME account.
  </p>
  
  <h3>
      2.2 Information uploaded when you use the service.
  </h3>
  
  <p>
      For example, the avatar you uploaded and the photos you shared when using MXCOME.
  </p>
  
  <p>
      Some of our services may require you to provide specific personal sensitive information to achieve specific functions. If you choose not to provide such information, you may not be able to use certain functions in the service normally, but it will not affect your use of other functions in the service. If you voluntarily provide your personal sensitive information, it means that you agree that we will handle your personal sensitive information in the purpose and manner described in this policy.
  </p>
  
  <h3>
      2.3 The information we get when you use the service.
  </h3>
  
  <p>
      Log information. When you use our service, we may automatically collect relevant information and store it as service log information.
  </p>
  
  <p>
      Device information. Such as device models and operating system versions.
  </p>
  
  <p>
      Software information. For example, with your authorization, we may collect information about the mobile applications and other software you use for the third-party applications that cooperate with MXCOME to ensure the security of the operating environment or provide services.
  </p>
  
  <p>
      IP address
  </p>
  
  <p>
      Service log information. For example, information searched and viewed when using our service, service failure information, referral website and other information.
  </p>
  
  <p>
      Communication log information. For example, the account, communication time and duration you used to communicate when using our service.
  </p>
  
  <p>
      Location information
  </p>
  
  <p>
      When you use location-related services, we may record the location information of your equipment in order to provide you with related services. When you use the service, we may obtain your geographical location information through IP address, GPS, WLAN (such as WiFi) or base station.
  </p>
  <p>
      The information provided by you or other users when using the service may include your geographical location information, such as the information of your location that may be included in the account information provided by you, and the geographical indication information contained in the photos shared by you or others.
  </p>
  
  <p>
      Other related information
  </p>
  
  <p>
      In order to help you use our products or services better, we will collect relevant information. For example, we collect your friend list, group list information and voiceprint feature value information. In order to ensure that you can contact people you know when using our service, if you choose to turn on the function of importing address book, we may encrypt the name and telephone number of your contact person and collect only the encrypted information.
  </p>
  
  <p>
      Information shared by other users contains your information. For example, photos or videos shared by other users may contain your information.
  </p>
  
  <p>
      Information obtained from third-party partners. We may obtain information that you generate or share when using third-party partner services. For example, when you log in to a third-party partner service with an MXCOME account, we will get the name and login time of your login to the third-party partner service, so as to facilitate your authorization management. Please read the user agreement or privacy policy of the third-party partner service carefully.
  </p>
  
  <h3>
      III. How do we use the collected information?
  </h3>
  We strictly abide by the provisions of laws and regulations and the agreement with users, and use the collected information for the following purposes. If we use your information beyond the following purposes, we will explain it to you again and provide you with services with your consent.
  
  <p>
      Meet your personalized needs, such as language setting, location setting and personalized help service.
  </p>
  
  <p>
      Product development and service optimization For example, when our system breaks down, we will record and analyze the information generated when the system breaks down and optimize our service.
  </p>
  
  <p>
      Security. For example, we will use your information for authentication, security prevention, anti-fraud monitoring, archive backup, customer security services and other purposes. For example, the security software you have downloaded or installed will detect malicious programs or viruses or identify fraudulent information for you.
  </p>
  
  <p>
      Software management
  </p>
  
  <p>
      For example, software certification, software upgrade, etc.
  </p>
  
  <p>
      Invite you to participate in the survey about our service.
  </p>
  
  <p>
      In order to make you have a better experience, improve our services or use them for other purposes with your consent, we may use the information collected through some services for other services. For example, use the information when you use one of our services to show you personalized content in another service, and use it for user research, analysis and statistics.
  </p>
  
  <p>
      In order to ensure the security of the service and help us better understand the operation of our application, we may record relevant information, such as the frequency of your application, fault information, overall usage, performance data and the source of the application. We will not combine the information we stored in the analysis software with the personally identifiable information you provided in the application.
  </p>
  
  <p>
      The information you shared
  </p>
  
  <p>
      You can share your relevant information with your friends, family and other users through our service. For example, the text and photos, voice and pictures that you shared publicly in the MXCOME moments of friends.
  </p>
  
  <p>
      Please note that this may contain sensitive information such as your personal identity information and personal property information. Please carefully consider disclosing your relevant personal sensitive information.
  </p>
  
  <p>
      You can control the range of information you share in your privacy settings, and you can also delete the information you share publicly in your settings or in the guidelines we provided. Please note, however, that this information may still be stored independently by other users or unrelated third parties outside our control.
  </p>
  
  <h3>
      IV. How do you manage your information?
  </h3>
  
  <p>
      You can access, modify and delete the registration information and other personal information provided by you in the process of using our service, or you can contact us according to the notification guidelines. The scope and manner in which you access, modify and delete personal information will depend on the specific service you use.
  </p>
  <p>
      For example, if you want to stop sharing your location information when using location-related services, you can turn off the mobile phone location function and turn off the software and hardware service providers and communication service providers. It is suggested that you should read the relevant guidelines carefully.
  </p>
  
  <p>
      We will collect and use your information only to realize the functions of our products or services in accordance with this policy. If you find that we collect and use your personal information in violation of laws, administrative regulations or the agreement of both parties, you can ask us to delete it.
  </p>
  
  <p>
      If you find that your personal information collected and stored by us is wrong, you can also ask us to correct it. Please contact us through the contact information listed in this policy.
  </p>
  
  <p>
      When you access, modify or delete related information, we may require you to authenticate to ensure the security of your account.
  </p>
  
  <p>
      Please understand that due to technical limitations or other reasons, we may not be able to meet all your requirements. However, we will reply to your request within a reasonable period of time.
  </p>
  
  <h3>
      V. The information we shared
  </h3>
  
  <p>
      We strictly restrict the sharing of information in compliance with laws and regulations. For example, with your prior consent, we may share your personal information with third parties. For the purpose of external processing, we may cooperate with third-party partners (third-party service providers, contractors, agents, advertising partners, application developers, etc., for example, communication service providers who send emails or push notifications on our behalf, and map service providers who provide location services for us (they may not be located in your area) share your personal information, and let them handle the above information for us in accordance with our instructions, privacy policies and other relevant confidentiality and security measures, and use it for the following purposes:
  </p>
  
  <h3>
      VI. Our service to you
  </h3>
  
  <p>
      Achieve the purpose stated in the section "How do we use the collected information";
  </p>
  
  <p>
      Fulfill our obligations and exercise our rights in this policy;
  </p>
  
  <p>
      Understand, maintain and improve our service.
  </p>
  
  <p>
      If we share your information with the above-mentioned third parties, we will use encryption, anonymization and other means to ensure your information security.
  </p>
  
  <p>
      With the continuous development of our business, when a transaction such as merger, acquisition or asset transfer leads to the sharing of your personal information with a third party, we will inform you of the relevant situation through push notices, announcements and other forms. We will continue to protect your personal information in accordance with laws and regulations and not less than the standards required by this policy or ask new managers to continue to protect your personal information.
  </p>
  
  <p>
      We will use the collected information for big data analysis. For example, we use the collected information to analyze and form urban heat maps or industry insight reports that do not contain any personal information. We may disclose and share with our partners the statistically processed information without identification content, so as to understand how users use our services or let the public know the overall usage trend of our services.
  </p>
  
  <h3>
      VII. Information we may send you.
  </h3>
  
  <p>
      When you use our service, we may send you emails, text messages, information or push notifications.
  </p>
  
  <p>
      You can choose to unsubscribe from service-related announcements on the device according to our relevant tips.
  </p>
  
  <p>
      We may issue service-related announcements to you when necessary (for example, when a service is suspended due to system maintenance).
  </p>
  
  <p>
      You may not be able to cancel these service-related announcements that are not advertisements in nature.
  </p>
  
  <h3>
      VIII. Time limit for storing information
  </h3>
  
  <p>
      Generally speaking, we only keep your personal information for the shortest time necessary to achieve the purpose.
  </p>
  
  <p>
      These purposes are reasonably necessary to implement the relevant service agreement or this policy, safeguard social public interests, and protect the personal and property safety or other legitimate rights and interests of our customers, us or our affiliated companies, other users or employees.
  </p>
  
  <p>
      When our products or services stop operating, we will notify you in the form of push notice and announcement, and delete or anonymize your personal information within a reasonable period of time.
  </p>
  
  <h3>
      IX. Information security
  </h3>
  
  <p>
      We provide corresponding security for your information to prevent its loss, improper use, unauthorized access or disclosure.
  </p>
  
  <p>
      We strictly abide by laws and regulations to protect users' communication secrets.
  </p>
  
  <p>
      We will use all kinds of security protection measures within a reasonable security level to ensure the security of information.
  </p>
  
  <p>
      We establish special management systems, processes and organizations to ensure information security.
  </p>
  
  <p>
      In the event of personal information disclosure and other security incidents, we will start an emergency plan to prevent security incidents from expanding, and inform you in the form of push notices and announcements.
  </p>
  
  <h3>
      X. Scope of application
  </h3>
  
  <p>
      This policy applies to all our services. Some services have their own specific privacy guidelines/statements, which more specifically explain how we handle your information in the service. If there is any inconsistency between this policy and the privacy guidelines/statements of specific services, please refer to the specific privacy guidelines/statements.
  </p>
  
  <p>
      Please note that this policy does not apply to services provided by other companies or individuals. For example, you log in to services provided by other companies or individuals by using an MXCOME account. Your use of this third-party service is subject to its privacy policy (not this policy), and you need to read its contents carefully.
  </p>
  
  <h3>
      XI. Contact us
  </h3>
  
  <p>
      If you have any questions about this policy or other related matters, please contact us through cs@mxcome.com. We will review the issues and reply as soon as possible after verifying your user identity.
  </p>
  
  <h3>
      XII. Change
  </h3>
  
  <p>
      We may revise the policy in due course. If these changes will lead to the impairment of your rights under this policy, we will inform you by sending you an email at a prominent position on the page before the changes take effect. In this case, if you continue to use our service, you agree to be bound by the revised policy.
  </p>
  
  <h3>
      Agreement on MXCOME account use
  </h3>
  
  <p>
      In order to make effective use of MXCOME account resources and safeguard the legitimate rights and interests of users, the MXCOME Account Rules (hereinafter referred to as "the Rules") are formulated. You should read and abide by these rules. Please read carefully and fully understand the contents of the clauses, especially the clauses exempting or limiting MXCOME's liability, the clauses restricting users' rights, the agreed dispute settlement methods, and the separate agreements or rules for opening or using a certain service. Restrictions, exemption clauses or other clauses involving your major rights and interests may remind you to pay attention in bold or underlined forms.
  </p>
  
  <p>
      Unless you have fully read, fully understood and accepted all the terms of these rules, you have no right to apply for and use MXCOME account and related services. If you click "Agree" or "Next", or if you apply for or use MXCOME account and related services, or express your acceptance of these rules in any other express or implied way, it will be deemed that you have read and agreed to sign these rules. This rule has legal effect between you and MXCOME and becomes a binding legal document for both parties.
  </p>
  
  <p>
      If you do not have full capacity for civil conduct due to age, intelligence or other factors, please read and decide whether you agree to this rule or not with your legal guardian (hereinafter referred to as "Guardian").
  </p>
  
  <h3>
      1. Scope of rules
  </h3>
  
  <p>
      1.1. These rules are related rules formulated by MXCOME on obtaining and using MXCOME accounts. This rule applies to all software and services provided by MXCOME that need to register or use MXCOME account.
  </p>
  
  <p>
      1.2. When you use services or third-party services through MXCOME, you must abide by the separate agreements of each service.
  </p>
  
  <h3>
      2. Acquisition of MXCOME account
  </h3>
  
  <p>
      2.1. You can apply for registration of MXCOME account for free or paid through, including but not limited to, the software client (the specific method is subject to the actual official provision of MXCOME).
  </p>
  
  <p>
      2.2. After you complete the application for registration, MXCOME authorizes you to log in and use MXCOME's software or services in accordance with these Rules, or authorizes you to use the account number for other purposes explicitly permitted by MXCOME in accordance with MXCOME's business rules.
  </p>
  
  <p>
      2.3. Any entity applying for or using any MXCOME account by any means shall be bound by these Rules.
  </p>
  
  <h3>
      3. User identity information verification
  </h3>
  
  <p>
      3.1. You need to fill in some necessary information when applying for an MXCOME account. Please keep this information up-to-date so that MXCOME can help you or better serve you. If there are special provisions, you need to fill in real identity information (including but not limited to mobile phone number, ID number, etc.). If the information you fill in is incomplete or inaccurate, you may not be able to use the service or be restricted in the use process.
  </p>
  
  <p>
      3.2. MXCOME works with users to protect personal information, as protecting users' personal information is a basic principle of MXCOME. Without your consent, MXCOME will not disclose your personal information to any company, organization or individual other than MXCOME.
  </p>
  
  <p>
      Please read and fully understand the Privacy Policy on how MXCOME collects, uses, stores and shares your personal information.
  </p>
  
  <h3>
      4. Usage of MXCOME account
  </h3>
  
  <p>
      4.1. You can log in and use all kinds of software and services provided by MXCOME with its account according to the business rules.
  </p>
  
  <p>
      4.2. In addition to using the software and services provided by MXCOME, you can also use MXCOME account to log in and use some third-party software, websites or services. Please know and agree that these websites or services are not operated by MXCOME (unless those specified by MXCOME). You should notice the security and availability of such third-party websites or services by yourself, and take the relevant risks and responsibilities by yourself.
  </p>
  
  <p>
      4.3. When you log in and use various software and services provided by MXCOME or a third party, please note that your personal information such as MXCOME avatar and nickname may also be displayed to yourself, MXCOME friends or other users through MXCOME services or services provided by third parties. If you refuse to share the above information with other services of MXCOME or services provided by third parties, you can modify it in various software and services provided by MXCOME or third parties.
  </p>
  
  <p>
      4.4. If you haven't logged in or used the registered MXCOME account for a long time, MXCOME has the right to take back the MXCOME account, and you can't continue to use the corresponding account.
  </p>
  
  <h3>
      5. Security of MXCOME account
  </h3>
  
  <p>
      5.1. The MXCOME password is set by you. You should properly keep your MXCOME account and password (including but not limited to two-step verification password, payment password, etc., hereinafter referred to as "password"), and be responsible for all activities carried out by the MXCOME account and related consequences.
  </p>
  
  <p>
      5.2. MXCOME shares the responsibility of maintaining the security of MXCOME accounts with you. We will take and constantly update technical measures to protect the security of your MXCOME account on the server side.
  </p>
  
  <p>
      5.3. You must take necessary measures to protect the security of your account, including but not limited to keeping MXCOME account and password properly, installing anti-virus software, changing your password regularly and other measures.
  </p>
  
  <h3>
      6. Abnormal login of MXCOME account
  </h3>
  
  <p>
      6.1. If your MXCOME account is not logged in by yourself, please modify the two-step verification password in time.
  </p>
  
  <p>
      6.2. You promise to take good care of all information or equipment that may be used to retrieve your MXCOME account, so as to prevent others from logging into your MXCOME account by impersonating you to appeal. You understand and agree that the losses caused by such circumstances should be borne by yourself.
  </p>
  
  <h3>
      7. User behavior norms
  </h3>
  
  <p>
      Users should abide by laws and regulations, as well as the provisions of these rules and other separate agreements in the process of using MXCOME account. Besides:
  </p>
  
  <p>
      7.1. You shall not maliciously register or obtain an MXCOME account. Cases of malicious registration and obtaining MXCOME account include but are not limited to
  </p>
  
  <p>
      Fail to register or obtain an MXCOME account through the software or services provided by or explicitly licensed by MXCOME ; Or register the MXCOME account through any software or service in a non-manual way; Or obtain MXCOME account by frequent and batch registration.
  </p>
  
  <p>
      Register or use an MXCOME account beyond the purpose of communication between normal friends or users, including but not limited to registering, acquiring or using an MXCOME account for sending harassing information, spam, advertisements, fraudulent information or achieving illegal purposes.
  </p>
  
  <p>
      Other acts of registering, obtaining or using an MXCOME account by means or purposes other than those expressly permitted by MXCOME.
  </p>
  
  <p>
      If, in the independent judgment of MXCOME, it is considered that the account you use is a maliciously registered or acquired account, MXCOME has the right to take measures such as restricting, suspending or terminating the use of the corresponding MXCOME account.
  </p>
  
  <p>
      7.2. MXCOME's authorization to use the MXCOME account according to these Rules is limited to the initial registrant. Without the permission of MXCOME, the initial registrant shall not give, borrow, lease, transfer or sell the MXCOME account, or otherwise permit other entities to use the MXCOME account. Non-initial registrants may not use MXCOME account by donation, inheritance, lease, transfer or any other means.
  </p>
  
  <p>
      7.3. If the account you are currently using is not registered by your initial application or obtained by other means provided by MXCOME, you are not allowed to log in or do any operation with this account, and please inform MXCOME or the initial registrant of this account at the first time.
  </p>
  
  <p>
      7.4. Specification of Information Content (1) The information content mentioned in this article refers to any content produced, copied, published and disseminated by users in the process of using this software and services, including but not limited to the registered information such as the avatar, name, user description and profile of MXCOME account or functional account, or the sent information such as text, voice, pictures, videos and expressions, pictures and texts of wechat moment, related link pages, and other information from MXCOME. (2) You understand and agree that as MXCOME has always been committed to providing users with a civilized, healthy, standardized and orderly network environment, you are not allowed to use MXCOME account or function account or this software and service to make, copy, publish or disseminate the following contents that interfere with the normal operation of MXCOME and infringe upon the legitimate rights and interests of other users or third parties. It is forbidden to publish and disseminate obscene, pornographic, child sexual abuse, gambling, violence and other related contents.
  </p>
  
  <h3>
      8. Responsibility scope
  </h3>
  
  <p>
      8.1. You understand and agree that you should bear all responsibilities arising from all activities under your MXCOME account.
  </p>
  
  <p>
      8.2. If your MXCOME account is stolen due to the platform, we will bear corresponding responsibilities according to law. If it is not caused by MXCOME, you should bear the relevant legal responsibilities and losses yourself.
  </p>
  
  <p>
      8.3. In order to avoid disputes, you may not transfer your MXCOME account with or without compensation. Otherwise, you should bear any responsibility arising therefrom, and MXCOME reserves the right to pursue the legal responsibility of the above-mentioned actors.
  </p>
  
  <h3>
      9. Restriction, freezing or termination of the use of MXCOME account.
  </h3>
  
  <p>
      9.1. If you violate relevant laws and regulations, these Rules or other relevant agreement rules, MXCOME has the right to restrict, suspend, freeze or terminate your use of MXCOME account, and decide whether to resume your use of MXCOME account according to the actual situation.
  </p>
  
  <p>
      9.2. If MXCOME finds that you are not the initial registrant of the account, MXCOME has the right to terminate your use of the account without notice.
  </p>
  
  <p>
      9.3. In accordance with these Rules, relevant laws and regulations or other relevant agreements and rules, MXCOME takes measures such as restricting, suspending, freezing or terminating your use of MXCOME account. The losses caused to you (including but not limited to communication interruption, user data, emails, virtual property and related data, value-added services, products or services, etc.) shall be borne by you.
  </p>
  
  <h3>
      10. Account security protection
  </h3>
  
  <p>
      In order to protect the security of your MXCOME account and enable you to use this service more safely, MXCOME may continuously upgrade and improve our security technical protection measures. If MXCOME assumes that your account number may be at risk of being stolen, with the purpose of  protecting your account security and property from losses, it may take some measures, such as sending you a certain reminder and temporarily freezing your account use. You should follow the tips or guidelines to reduce the risk of your account being stolen or restore your account use.
  </p>
  
  <h3>
      11. Customer service
  </h3>
  
  <p>
      If you have any objection to any measures taken by MXCOME to restrict, freeze or terminate MXCOME account, or have other problems in the process of using MXCOME account, you can contact cs@mxcome.com, the customer service department of MXCOME, and we will give you necessary help.
  </p>
  
  <h3>
      12. Copyright statement
  </h3>
  
  <p>
      12.1. When users upload their original content to MXCOME, the copyright belongs to the users themselves. However, MXCOME enjoys a permanent, irrevocable, free and non-exclusive right to use and sublicense all over the world, including but not limited to modification, reproduction, distribution, exhibition, adaptation, assembly, publication, translation, information network dissemination and publication. Users voluntarily agree to exempt MXCOME platform from all possible legal responsibilities caused by copyright disputes.
  </p>
  
  <p>
      12.2. The unique logo, layout design, arrangement and other copyrights of "MXCOME" belong to "MX Come" and shall not be copied or reproduced without permission.
  </p>
  
  <p>
      12.3. The information obtained by users from the service of "MXCOME" shall not be copied or reproduced at will without permission.
  </p>
  
  <p>
      12.4. Any content using "MXCOME" shall be marked with "from" MXCOME "and the name of the author. If it is necessary to pay the remuneration according to the law, it shall notify the APP and the author and pay the remuneration, and bear all legal responsibilities independently.
  </p>
  
  <p>
      12.5. “MXCOME "enjoys the right of use and priority of all works for other purposes, including but not limited to websites, e-magazines, print publishing, etc. The author is deemed to agree to this clause when the APP publishes the content.
  </p>
  
  <p>
      12.6. All contents of "MX Come" only represent the author's own position and viewpoint. It has nothing to do with the APP, and the author shall bear all legal responsibilities.
  </p>
  
  <p>
      12.7. If the content of "MXCOME" is maliciously reproduced, "MXCOME" reserves the right to resort to law.
  </p>
  
  <h3>
      13. Platform safety
  </h3>
  
  <p>
      The primary task of MXCOME is to provide security and protection for the data of our users, creators, partners, suppliers and employees, including personal information, account numbers, files, contents and other private information, as well as our products and services. Unauthorized access to the MXCOME platform (including our website www.mxcome.com, applications, networks and related infrastructure or networks), or access to sensitive commercial or personal confidential information are not allowed. We explicitly prohibit any attempt to undermine or abuse the safety, integrity or reliability of our platform, products or services.
  </p>
  
  <h3>
      please don't:
  </h3>
  <p>
      (1) Try to access the MXCOME platform in an unauthorized way, and do not make a false or misleading version of the MXCOME platform;
  </p>
  <p>
      (2) Create or distribute malicious files, contents or messages containing viruses, Trojan horses, computer worms, logic bombs or other materials that may be harmful to communities or platforms;
  </p>
  <p>
      (3) Try to obtain, acquire or request login credentials or other sensitive information, including non-public data, from MXCOME or other users by using automated scripts, web crawlers, software, deception techniques or any other means;
  </p>
  <p>
      (4) Spread spam, phishing or fraudulent content by using MXCOME account by false or fraudulent means, attempt to commit cyber crimes or access other people's content, account, system or data without authorization;
  </p>
  <p>
      (5) Modify, adapt, translate, restore, disassemble, decompile or create any derivative products based on MXCOME, including any files, tables or documents, or attempt to regenerate any source programs, algorithms, methods or technologies contained in MXCOME;
  </p>
  <p>
      (6) Provide others with access to your account credentials, or allow others to conduct operations that violate our community self-discipline convention;
      Click on the suspicious hyperlink or participate in asking for detailed information, password, authentication qualification, financial or other personal information about your MXCOME account;
  </p>
  <p>
      （7）Please pay special attention to identify official links, and beware of being deceived (standard link format www.mxcome.com/).
  </p>
  
  <h3>
      14. Promotion code, discount code and coupon terms and conditions
  </h3>
  
  <p>
      14.1. The promotion codes, discount codes and coupons provided are only applicable to one-time shopping through our APP and are subject to the terms and conditions.
  </p>
  
  <p>
      14.2. The promotion codes, discount codes and coupons provided are neither refundable nor convertible into cash. Any remaining unused amount will be invalidated.
  </p>
  
  <p>
      14.3 If your order is cancelled for any reason or is eligible for a refund, the promotion code, coupon code or coupon used in the order will be invalid, and we do not need to refund or make any compensation.
  </p>
  
  <p>
      14.4 We reserve all rights to terminate or change the above offer at any time and from time to time without notice. In case of any dispute, our decision will be final and decisive.
  </p>
  
  <p>
      These terms and conditions form an integral part of the general terms and conditions.
  </p>
  
  <h3>
      15. About activities and tasks
  </h3>
  
  <p>
      Task types are different activities published by MXCOME platform. Task rules are specific rules of activities. Tasks are divided into sign-in, growth and activity tasks. Within the scope of the activity rules, the user completes the specified task according to the rules and obtains the corresponding reward, and the final interpretation right and the main responsibility of the activity belong to the activity organizer;
  </p>
  
  <h3>
      16. Force majeure or other exemptions
  </h3>
  <p>
      [Force Majeure] You understand and agree that in the course of using this service, you may encounter force majeure and other risk factors, which will interrupt or terminate the service under this service agreement. Force majeure refers to unforeseeable, insurmountable and unavoidable objective events that have a significant impact on one or both parties, including but not limited to information network equipment maintenance, information network connection failure, computer, communication or other system failure, power failure, strike, labor dispute, riot, uprising, riot, insufficient productivity or means of production, fire, flood, storm, explosion, war, government behavior, changes in laws and regulations, etc. When the above situation occurs, MXCOME will try its best to cooperate with relevant departments in the first time to make repairs in time. However, MXCOME is exempt from liability within the scope permitted by law for the losses caused to you.
  </p>
  <p>
      [Other reasons for exemption] You understand and agree that MXCOME will not be responsible for service interruption or termination caused by the following reasons, provided that it complies with the law:
  </p>
  <p>
      (1) Damaged by computer viruses, Trojan horses or other malicious programs and hacker attacks;
  </p>
  <p>
      (2) Broken computer software, system, hardware and communication lines of the user or MXCOME;
  </p>
  <p>
      （3）Improper user operation;
  </p>
  <p>
      (4) Users use this service in a way that is not authorized by MXCOME;
  </p>
  <p>
      (5) Other circumstances beyond MXCOME's control or reasonable prediction.
  </p>
  
  <h3>
      17. Others
  </h3>
  <p>
      17.1. When you apply for or use the MXCOME account and related services, it shall be deemed that you have read and agreed to be bound by these Rules. MXCOME has the right to modify the provisions of these rules when necessary. You can check the latest version of this rule on the relevant page of MXCOME. After the terms of these rules are changed, if you continue to use the MXCOME account and related services, it will be deemed that you have accepted the changed rules.
  </p>
  
  <p>
      17.2. The titles of all clauses in these Rules are for reading convenience only, and have no actual meaning in themselves, which can not be used as the basis for the interpretation of these Rules.
  </p>
  
  <p>
      17.3. If the provisions of these Rules are partially invalid or unenforceable for any reason, the remaining provisions shall remain valid and binding on both parties.
  </p>
  
  <h3>
      Exemption agreement
  </h3>
  
  <p>
      All use or registration of MXCOME APP related products shall be deemed as having carefully read and agreed to this agreement. The specific contents of the agreement are as follows:
  </p>
  
  <p>
      1. The user has understood and agreed that the risks of using MXCOME APP products (MXCOME APP products refer to but not limited to apps, web pages, applets, robots, services, contents, etc., hereinafter referred to as MXCOME products) are entirely borne by yourself; All the consequences arising from its use of MXCOME APP products are also borne by yourself, and MXCOME APP is not responsible for this.
  </p>
  
  <p>
      2. The information publisher is responsible for the authenticity, accuracy and legality of the content sent by third parties (including but not limited to users, third-party robots, applets, etc.) of MXCOME APP, and MXCOME App does not provide any guarantee or assume any responsibility.
  </p>
  
  <p>
      3. If any text, pictures, audio and video, documents and other materials reprinted by a third party on MXCOME APP infringe on the intellectual property rights or other rights of a third party, the responsibility shall be borne by the author or the reprinter, and MXCome App shall not bear any responsibility for it.
  </p>
  
  <p>
      4. MXCOME APP will not take any responsibility for the impact and loss caused to users due to its failure to provide normal communication or service, but will try its best to reduce the impact caused to users.
  </p>
  
  <p>
      5. MXCOME APP will not be responsible for data loss or other losses caused by your access to or use of MXCOME products.
  </p>
  
  <p>
      6. MXCOME APP will not bear any responsibility if it deletes or fails to store relevant data, causing losses to users.
  </p>
  
  <p>
      7. MXCOME APP does not guarantee the accuracy and completeness of the external link set for the convenience of users, and MXCOME APP does not assume any responsibility for any content on this external link.
  </p>
  
  <p>
      8. Users should bear their own risks and responsibilities for any suggestions or information obtained from MXCOME products, and MXCOME APP does not assume any responsibilities.
  </p>
  
  <p>
      9. The relevant statements, amendments, updates and final interpretation rights of this agreement belong to MXCOME APP.
  </p>
  </body>
  </html>
  
   `
}
